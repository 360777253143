import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

<iframe
  allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
  style={{
    width: '100%',
    maxWidth: '660px',
    overflow: 'hidden',
    borderRadius: '10px',
    border: 0,
    height: '450px',
    color: 'blue'
  }}
  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
  src="https://embed.music.apple.com/us/album/here-today/1207065932"
></iframe>


      </header>






    </div>
  );
}

export default App;
